import {Injectable} from '@angular/core';
import {finalize, map, switchMap} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {User} from '../models/user.model';
import {isApiTokenExpired} from '../../../utils/token.util';
import {AUTHENTICATION_HEADER_KEYS, AUTHENTICATION_STORAGE_KEYS} from './authentication-keys';


@Injectable()
export class AuthenticationService {
	private onLogout$: Subject<any> = new Subject<any>();
	private onLogin$: Subject<any> = new Subject<any>();

	constructor(private httpClient: HttpClient) {
		this.watchStorageChangesOnOtherTabs();
	}

	login(username: string, password: string): Observable<User> {
		const endpoint = 'api/auth/login';
		const params: URLSearchParams = new URLSearchParams();
		params.set('username', username);
		params.set('password', password);
		let roles: Array<string>;

		return this.httpClient.post(endpoint, params.toString(), {
			observe: 'response',
			headers: new HttpHeaders({
				'Access-Control-Expose-Headers': AUTHENTICATION_HEADER_KEYS.apiToken,
				'Content-Type': 'application/x-www-form-urlencoded'
			})
		}).pipe(switchMap((response: HttpResponse<any>) => {
			this.saveAuthTokens(response.body.token);
			roles = response.body.roles;
			return this.httpClient.get('api/web-ui/clinic/employee');
		}), map((res: any) => {
			const user = User.deserialize({...res, roles: roles});
			this.onLogin$.next(null);
			return user;
		}));
	}

	logout(): Observable<any> {
		const endpoint = 'api/auth/logout';
		return this.httpClient.post(endpoint, {}).pipe(finalize(() => {
			this.clearAuthTokens();
			this.onLogout$.next(null);
		}));
	}

	onLogin(): Observable<any> {
		return this.onLogin$.asObservable();
	}

	onLogout(): Observable<any> {
		return this.onLogout$.asObservable();
	}


	forgotPassword(email: string): Observable<any> {
		const params = {username: email};
		return this.httpClient.put(`api/web-ui/account-management/reset-password`, {}, {params: params});
	}

	setPassword(verificationToken: string, newPassword: string): Observable<any> {
		const body = {
			verificationToken: verificationToken,
			newPassword: newPassword
		};
		return this.httpClient.put(`api/web-ui/account-management/set-password`, body);
	}

	hasApiToken(): boolean {
		return this.getApiToken() !== null;
	}

	/**
	 * Returns the token to use for authorization with the API, or null.
	 */
	getApiToken(): string {
		return localStorage.getItem(AUTHENTICATION_STORAGE_KEYS.apiToken);
	}

	isApiTokenExpired(): boolean {
		return this.hasApiToken() && isApiTokenExpired(this.getApiToken());
	}

	private saveAuthTokens(apiToken: string): void {
		localStorage.setItem(AUTHENTICATION_STORAGE_KEYS.apiToken, apiToken);
	}

	private clearAuthTokens(): void {
		localStorage.removeItem(AUTHENTICATION_STORAGE_KEYS.apiToken);
	}

	private watchStorageChangesOnOtherTabs(): void {
		window.addEventListener('storage', this.emitLogoutIfStorageChangeSaysItsNecessary.bind(this));
	}

	private emitLogoutIfStorageChangeSaysItsNecessary(event: StorageEvent): void {
		if (event.storageArea !== localStorage) {
			return;
		}
		if (event.key == null && event.oldValue == null && event.newValue == null) { // storage cleared.
			this.onLogout$.next(null);
		} else if (event.key === AUTHENTICATION_STORAGE_KEYS.storedUser && event.newValue !== null) {
			// If a new user logs in, reload other tabs as well
			window.location.reload();
		} else if (event.key === AUTHENTICATION_STORAGE_KEYS.apiToken && event.newValue == null) { // only apiToken cleared.
			this.onLogout$.next(null);
		}
	}
}
